var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.attention
      ? _c("p", [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("\n      Att:\n    "),
          ]),
          _vm._v("\n    " + _vm._s(_vm.attention) + "\n  "),
        ])
      : _vm._e(),
    _vm.addressOnOneLine
      ? _c("div", [_c("p", [_vm._v(_vm._s(_vm.oneLineAddress))])])
      : _c("div", [
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.address.line1) +
                ",\n      " +
                _vm._s(_vm.address?.line2) +
                "\n    "
            ),
          ]),
          _c("p", [
            _vm.address.city
              ? _c("span", [
                  _vm._v("\n        " + _vm._s(_vm.address.city) + ",\n      "),
                ])
              : _vm._e(),
            _vm.address.state_province_region
              ? _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.address.state_province_region) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm.address.zip_postal_code
              ? _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.address.zip_postal_code) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.address.county
            ? _c("p", [
                _vm._v("\n      " + _vm._s(_vm.address.county) + "\n    "),
              ])
            : _vm._e(),
          _vm.address.country
            ? _c("p", [
                _vm._v("\n      " + _vm._s(_vm.address.country) + "\n    "),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }