<template>
  <div>
    <p v-if="attention">
      <span class="font-weight-bold">
        Att:
      </span>
      {{ attention }}
    </p>

    <div v-if="addressOnOneLine">
      <p>{{ oneLineAddress }}</p>
    </div>
    <div v-else>
      <p>
        {{ address.line1 }},
        {{ address?.line2 }}
      </p>

      <p>
        <span v-if="address.city">
          {{ address.city }},
        </span>

        <span v-if="address.state_province_region">
          {{ address.state_province_region }}
        </span>

        <span v-if="address.zip_postal_code">
          {{ address.zip_postal_code }}
        </span>
      </p>

      <p v-if="address.county">
        {{ address.county }}
      </p>

      <p v-if="address.country">
        {{ address.country }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderItemAddress',
  props: {
    address: {
      type: Object,
      default: null,
    },
    attention: {
      type: String,
      default: '',
    },
    addressOnOneLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    oneLineAddress() {
      if (!this.addressOnOneLine || !this.address.line1 || !this.address.city || !this.address.zip_postal_code) return '-'
      return `${this.address.line1}${this.address.line2 ? ` ${this.address.line2}` : ''}, ${this.address.city},
        ${this.address?.county ? `${this.address.county},` : '' } ${this.address.state_province_region}
        ${this.address.zip_postal_code} ${this.address?.country || ''}`
    },
  },
}

</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
</style>
